export const sharedEnvironment = {
  version: '60',
  sentry: 'https://d160cc72fd9747a7b93d55abccec2818@sentry.io/1242955',
  tawktoPropertyId: '64dd1b5e94cf5d49dc6accd4',
  tawktoWidgetId: '1h7vr5q3o',
  dev: {
    auth0: {
      domain: 'dataformz-staging.auth0.com',
      client_id: '773IBUeu0TMtfYbDbV635EOjUgFClLSd',
    },
    googleMapsKey: 'AIzaSyCh4pkYzoBR6yl76XGyQex_4Vw8NXRAaJM',
  },
  prod: {
    auth0: {
      domain: 'dataformz.auth0.com',
      client_id: 'hVa07Rs8hJQk50xiYuoEYUxzXucdLfYy',
    },
    googleMapsKey: 'AIzaSyAIit30mi_KyqXSURb1WEkUsesy2T2dG8I',
  },
};
